import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/header/header'
import Steps from '../components/steps/steps'
import Partners from '../components/partnersBlock/partnersBlock'
import Testimonials from '../components/testimonials/testimonials'
import TextAccordion from '../components/textAccordion/textAccordion'
import Plan from '../components/plan/plan'
import Hero from '../components/hero/hero'
import Footer from '../components/footer/footer'

const WpPost = ({data}) => {
	const pageData = JSON.parse(data.wpPage.blocksJSON);
	const images = data.allWpMediaItem.edges;
	const LoadSection = ({ val, imageArray }) => {
		switch (val.attributes.name) {
			case "acf/steps" :
				return <Steps section={val} images={imageArray} page={data.wpPage.uri} />;
			case "acf/partners" :
				return <Partners section={val} images={imageArray} />;
			case "acf/testimonials" :
				return <Testimonials section={val} images={imageArray} ratings={data.wp.themeGeneralSettings.ratingsWidget} />;
			case "acf/plan" :
				return <Plan section={val} images={imageArray} />;
			case "acf/text-accordion" :
				return <TextAccordion section={val} />;
		  default:
			return "Block (" + val.attributes.name + ") not found. ";
		}
	};
  return (
	<div>
		<Layout data={data.wpPage.seo}></Layout>
		<Hero section={data.wpPage.hero} title={data.wpPage.title} ratings={data.wp.themeGeneralSettings.ratingsWidget}></Hero>
		<main className="main container container--full">
		  {
			pageData.map((section, i) => (
			  <LoadSection val={section} key={i} imageArray={images} />
			))
		  }
		</main>
		<Footer data={data.wp.acfOptionsFooter.footerOptions} popup={data.wp.acfOptionsHeader} page={data.wpPage.uri}></Footer>
	</div>
  )
}

export const query = graphql`
	query {
	  wpPage(uri: {eq: "/"}) {
	  id
	  title
	  blocksJSON
	  uri
	  hero {
		heroContent
		heroButtonText
		heroButtonLink {
		  url
		}
		heroImage {
		  sourceUrl
		  altText
		}
		heroMobileImage {
		  sourceUrl
		}
	  }
	  seo {
		breadcrumbs {
		  text
		  url
		}
		fullHead
		schema {
			raw
		}
	  }
	}

	wp {
		acfOptionsHeader {
			popup {
			  popupTitle
			  popupText
			  popupLinkText
			  popupLink {
					url
				}
			  popupImage {
					sourceUrl
				}
			}
		}
	  themeGeneralSettings {
		ratingsWidget {
		  ratingName
		  ratingNumber
		  ratingStars
		  ratingValue
		  ratingLink
		}
	  }
	  acfOptionsFooter {
		footerOptions {
		  copyrightMessage
		  facebook
		  fieldGroupName
		  footerLogo {
			sourceUrl
		  }
		  instagram
		  tagline
		  tiktok
		}
	  }
	}

	allWpMediaItem(filter: {databaseId: {in: [11252, 11256, 11258]}}) {
	  edges {
		node {
		  id
		  sourceUrl
		  databaseId
		}
	  }
	}
  }
`

export default WpPost